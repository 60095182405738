
import { onMounted, reactive, ref } from 'vue';
import { useToast } from 'primevue/usetoast';
import { useI18n } from 'vue-i18n';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import Toolbar from 'primevue/toolbar';
import InputText from 'primevue/inputtext';

import { Icon } from '../../components/ui';
import { useStores } from '@/store/Stores';

export default {
  components: {
    DataTable,
    Column,
    Button,
    Icon,
    Dialog,
    Toolbar,
    InputText
  },
  setup() {
    const { albumsStore, albumsStoreState } = useStores();

    const toast = useToast();
    const { t } = useI18n();

    const expandedRows = ref([]);
    const isShow = ref(false);
    const model = reactive({
      parent_id: null,
      name: null
    });

    albumsStore.init();

    const openModal = (parent_id) => {
      isShow.value = true;
      model.parent_id = parent_id;
    };

    const closeModal = () => {
      isShow.value = false;
      model.parent_id = null;
      model.name = null;
    };

    const submit = () => {
      albumsStore.saveChanges(model, closeModal, toast, t);
    };
    return {
      albumsStoreState,

      isShow,
      closeModal,
      openModal,
      model,
      expandedRows,
      submit
    };
  }
};
