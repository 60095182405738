import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = { class: "_heading-2" }
const _hoisted_3 = { class: "p-field mb-4" }
const _hoisted_4 = { class: "p-label" }
const _hoisted_5 = { class: "flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Column = _resolveComponent("Column")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_Toolbar = _resolveComponent("Toolbar")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Button, {
      class: "p-button-only-icon mb-4",
      onClick: _cache[0] || (_cache[0] = () => $setup.openModal(null))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_Icon, { name: "plus" })
      ]),
      _: 1
    }),
    _createVNode(_component_DataTable, {
      value: $setup.albumsStoreState.data,
      expandedRows: $setup.expandedRows,
      "onUpdate:expandedRows": _cache[1] || (_cache[1] = ($event: any) => (($setup.expandedRows) = $event)),
      dataKey: "id",
      responsiveLayout: "scroll"
    }, {
      expansion: _withCtx((slotProps) => [
        _createVNode(_component_DataTable, {
          value: slotProps.data.subsections,
          responsiveLayout: "scroll"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Column, {
              field: "name",
              header: _ctx.$t('photos_page.name')
            }, {
              body: _withCtx((slotProps) => [
                _createVNode(_component_router_link, {
                  class: "_link",
                  to: { name: 'PhotoAlbum', params: { photosID: slotProps.data.id } }
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(slotProps.data.name), 1)
                  ]),
                  _: 2
                }, 1032, ["to"])
              ]),
              _: 2
            }, 1032, ["header"]),
            _createVNode(_component_Column, {
              field: "photos_count",
              header: _ctx.$t('photos_page.photos')
            }, null, 8, ["header"]),
            _createVNode(_component_Column, {
              field: "processed_photos_count",
              header: _ctx.$t('photos_page.processed_photos')
            }, null, 8, ["header"])
          ]),
          _: 2
        }, 1032, ["value"])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_Column, {
          expander: true,
          headerStyle: "width: 3rem"
        }),
        _createVNode(_component_Column, null, {
          body: _withCtx((slotProps) => [
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.$style.imgPreview)
            }, [
              _createElementVNode("img", {
                src: slotProps.data.img_preview,
                alt: ""
              }, null, 8, _hoisted_1)
            ], 2)
          ]),
          _: 1
        }),
        _createVNode(_component_Column, {
          field: "name",
          header: _ctx.$t('photos_page.name')
        }, null, 8, ["header"]),
        _createVNode(_component_Column, {
          field: "all_photos_count",
          header: _ctx.$t('photos_page.all_photos')
        }, null, 8, ["header"]),
        _createVNode(_component_Column, {
          field: "all_processed_photos_count",
          header: _ctx.$t('photos_page.all_processed_photos')
        }, null, 8, ["header"]),
        _createVNode(_component_Column, null, {
          body: _withCtx((slotProps) => [
            _createVNode(_component_Button, {
              class: "p-button-only-icon",
              onClick: () => $setup.openModal(slotProps.data.id)
            }, {
              default: _withCtx(() => [
                _createVNode(_component_Icon, { name: "plus" })
              ]),
              _: 2
            }, 1032, ["onClick"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["value", "expandedRows"]),
    _createVNode(_component_Dialog, {
      visible: $setup.isShow,
      "onUpdate:visible": _cache[4] || (_cache[4] = ($event: any) => (($setup.isShow) = $event)),
      modal: true,
      draggable: false
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.$style.modal)
        }, [
          _createVNode(_component_Toolbar, { class: "p-4" }, {
            left: _withCtx(() => [
              _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t('photos_page.added_section')), 1)
            ]),
            right: _withCtx(() => [
              _createVNode(_component_Button, {
                onClick: $setup.closeModal,
                class: "p-button-outlined p-button-gray p-button-rounded p-button-only-icon"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_Icon, { name: "close" })
                ]),
                _: 1
              }, 8, ["onClick"])
            ]),
            _: 1
          }),
          _createElementVNode("form", {
            onSubmit: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => ($setup.submit && $setup.submit(...args)), ["prevent"])),
            class: _normalizeClass(_ctx.$style.content)
          }, [
            _createElementVNode("label", _hoisted_3, [
              _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t('photos_page.name')), 1),
              _createVNode(_component_InputText, {
                type: "text",
                modelValue: $setup.model.name,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.model.name) = $event))
              }, null, 8, ["modelValue"])
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_Button, {
                type: "submit",
                class: "ml-auto"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('save_change')), 1)
                ]),
                _: 1
              })
            ])
          ], 34)
        ], 2)
      ]),
      _: 1
    }, 8, ["visible"])
  ], 64))
}